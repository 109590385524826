<template>
    <div
        class="flex h-full w-full flex-col px-2.5 lg:border-t lg:bg-[#F5F5F5] lg:px-8 lg:pb-[50px] lg:pt-7"
    >
        <!-- all page's children will inhert parents layout -->

        <div
            class="mb-4 hidden border-b px-2 py-4 text-center text-xl font-bold lg:mx-auto lg:block lg:w-[1024px] lg:rounded-2xl lg:border-none lg:px-0 lg:py-0 lg:text-left"
        >
            Events
        </div>
        <div
            class="flex h-full w-full bg-white pt-4 lg:mx-auto lg:w-[1024px] lg:rounded-2xl lg:p-5 lg:px-11"
        >
            <NuxtPage />
        </div>
    </div>
</template>
<script setup></script>

<style lang="scss" scoped></style>
